import { Button, Dialog, DialogBackdrop } from "@headlessui/react";
import { CIP30ProviderProxy, CIP30Wallet } from "kuber-client";
import CrossIcon from "@src/components/icons/cross";
import { capitalize, cn } from "@src/utils/stringUtils";
import { useState } from "react";
import { useParams, useRouter } from "next/navigation";
import { setWallet } from "@src/store/user/wallet";
import { useDispatch } from "react-redux";
import { getDRepIdFromCip30Wallet } from "@src/utils/dRepUtils";
import { bech32 } from "bech32";
import {
  useDRepLoginMutation,
  useLazyGetDRepByIdQuery,
} from "@src/store/drep/api";
import { DRepMetadata, ILoginRequestBody } from "@src/store/drep/types";
import { toast } from "react-toastify";
// import { cookies } from "next/headers";

interface SignInDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SignInDialog({ isOpen, onClose }: SignInDialogProps) {
  const [currentSelectedProvider, setCurrentSelectedProvider] =
    useState<CIP30ProviderProxy | null>(null);
  const [getDRepInformationById] = useLazyGetDRepByIdQuery();
  const params = useParams();
  const [connectingProvider, setConnectingProvider] = useState<boolean>(false);

  const handleSelect = (provider: CIP30ProviderProxy) => {
    setCurrentSelectedProvider(provider);
  };

  const router = useRouter();

  const dispatch = useDispatch();
  const [dRepLogin] = useDRepLoginMutation();

  const enableWallet = async () => {
    setConnectingProvider(true);

    if (currentSelectedProvider !== null) {
      setTimeout(async () => {
        try {
          const enabledWallet = await currentSelectedProvider.enable({
            extensions: [
              {
                cip: 95,
              },
            ],
          });

          const drepIdBech32 = await getDRepIdFromCip30Wallet(enabledWallet);
          if (drepIdBech32) {
            const drepIdHex = Buffer.from(
              bech32.fromWords(
                bech32.decode(drepIdBech32 as string, 100).words,
              ),
            ).toString("hex");
            const today = new Date();
            const expirationDate = new Date(today);
            expirationDate.setDate(today.getDate() + 7);

            const [expMonth, expDay, expYear] = expirationDate
              .toLocaleDateString()
              .split("/")
              .map(Number);
            const messageUTF8 = `valid until ${expMonth}/${expDay}/${expYear}`;
            //@ts-ignore
            const dRepLoginRequestBody: ILoginRequestBody = {
              signature: await enabledWallet.signData(
                drepIdHex.slice(-56),
                Buffer.from(messageUTF8, "utf-8").toString("hex"),
              ),
              key: (await enabledWallet.cip95?.getPubDRepKey()) as string,
            };

            const response = await dRepLogin(dRepLoginRequestBody);
            if ("data" in response && response.data) {
              console.log(response.data);
            }
            dispatch(
              setWallet({
                name: currentSelectedProvider.name,
                icon: currentSelectedProvider.icon,
                instance: enabledWallet.instance,
              }),
            );
            if (!("dRepName" in params)) {
              const dRepInformation: DRepMetadata =
                await getDRepInformationById(drepIdBech32).unwrap();
              if (dRepInformation.token && dRepInformation.token.length !== 0) {
                router.push(`/${dRepInformation.token[0].name}`);
              } else {
                router.push("/drep/mint");
              }
            }
          }
        } catch (error) {
          toast.error(`${error}`);
          console.error("Error enabling wallet:", error);
        } finally {
          onClose();
          setConnectingProvider(false);
        }
      }, 1000);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="w-screen h-full relative z-[999] transition duration-300 ease-out item"
      transition={true}
    >
      <DialogBackdrop className="fixed inset-0 bg-overlay-100 flex justify-center items-center">
        <div className="bg-white px-10 py-[60px] rounded-2xl shadow-lg max-w-md w-full relative min-w-[700px] flex flex-col ">
          <CrossIcon
            onClick={onClose}
            className="absolute text-neutral-400 top-8 right-8 h-10 w-10 cursor-pointer"
          />

          <h2 className="h3 font-bold text-center">Choose your Wallet</h2>

          <div className="grid gap-5 overflow-y-scroll justify-center mt-9 mb-12">
            {CIP30Wallet.listProviders().map((provider) => (
              <WalletProviderDiv
                key={provider.name}
                provider={provider}
                className="min-w-[480px]"
                selected={
                  currentSelectedProvider
                    ? currentSelectedProvider.name === provider.name
                    : false
                }
                onClick={handleSelect}
                disabled={connectingProvider}
              />
            ))}
          </div>

          <div className="flex justify-center">
            <Button
              className={cn(
                "btn-primary min-w-[210px]",
                connectingProvider || currentSelectedProvider == null
                  ? "pointer-events-none"
                  : "",
              )}
              disabled={connectingProvider || currentSelectedProvider == null}
              onClick={enableWallet}
            >
              {connectingProvider ? "Connecting..." : "Connect Wallet"}
            </Button>
          </div>
        </div>
      </DialogBackdrop>
    </Dialog>
  );
}

interface WalletProviderProps {
  provider: CIP30ProviderProxy;
  className?: string;
  onClick?: any;
  selected?: boolean;
  disabled?: boolean;
}

const WalletProviderDiv = ({
  provider,
  className,
  selected,
  onClick,
  disabled,
}: WalletProviderProps) => {
  return (
    <button
      className={cn(
        "w-full flex rounded-[12px] max-w-[494px] items-center gap-4 py-4 px-8 transition-all bg-primary-100",
        className,
        selected ? "!bg-primary-500 !border-primary-500 text-white" : "",
        disabled
          ? "cursor-not-allowed !hover:bg-primary-100 pointer-events-none"
          : "cursor-pointer hover:bg-gray-100",
      )}
      onClick={() => {
        onClick(provider);
      }}
      disabled={disabled}
    >
      <img
        src={provider.icon}
        alt={`${provider.name} Icon`}
        className="w-8 h-8"
      />
      <span className="body1 font-semibold">{capitalize(provider.name)}</span>
    </button>
  );
};
